<script>
import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  data() {
    return {
    };
  },

  activated() {
    this.params.poolId = this.$route.query.poolId;
    this.getConfigList('goods-replenish-detail', true, false, 'CRM20210922000002718');
  },
  methods: {
    modalClick({ val, row }) {
      console.log(val.code);
      if (val.code === 'adjustment') {
        console.log(val.code);
      }
    },
  },
};
</script>
